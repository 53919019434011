<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <h4>Proses Latihan Soal</h4>
            </div>
            <br>
            <div class="row">
                <div class="col-12">
                <a href="#" class="btn btn-secondary">Prev</a>
                <a href="/soal-finish" class="btn btn-success float-right">Next</a>
                
                </div>
        </div>
        </div>
    </section>
</template>
<script>
    export default {
    name: 'SoalProcess',
    data() {
        return {
            
        };
    }
}
</script>
<style type="text/css" scoped>
</style>